import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import * as R from "ramda";
import { Subscription } from "rxjs";
import { map, take } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { GAME_CONFIG } from "../../../../../../../../core/config/custom/_parsed-game.config";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { OwDate, OwDateI } from "../../../../../../../../core/utility/ow-date.class";
import { OwPaginationDate } from "../../../../../../../../core/utility/ow-pagination.class";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { PrimarySelectors } from "../../../../../../../../store/primary";
import { AppState } from "../../../../../../../../store/state";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { UserService } from "../../../../../../../user/providers/user.service";
import { RANKS_NAME } from "../../../../../../constants/custom/rank.const";
import { Branch } from "../../../../../../interfaces/branch.interface";
import { NotificationsService } from "../../../../../../services/custom/notifications.service";
import { ApiPlayerService } from "../../../../../hud/api/custom/services/api-player.service";
import { TITLES } from "../../../../../hud/const/custom/titles.const";
import { CARD_TEMPLATE } from "../../../../../san-business/consts/custom/card-template.const";
import { DashboardRoleChangeService } from "../../../../../san-business/custom/services/dashboard-role-change.service";
import { ClearNotificationService } from "../../../../../san-business/custom/utils/clear-notification.service";
import { BusinessDateService } from "../../../../../shared-ui/services/custom/business-date.service";
import { ApiBusinessLeagueRankService } from "../../../../api/service/api-business-league-rank.service";
import { ApiBusinessRankService } from "../../../../api/service/api-business-rank.service";
import { ApiGameRanksService } from "../../../../api/service/api-game-ranks.service";
import { PAGINATION_TYPES } from "../../../../const/custom/pagination-types.const";
import { RANK_GAME_TYPE } from "../../../../const/custom/rank-game-type.const";
import { RANK_GROUP } from "../../../../const/custom/rank-group.const";
import { RANK_NAME } from "../../../../const/custom/rank-name.const";
import { MyIdObject, RankListBusiness, RankTaskProcessed } from "../../../../interfaces/custom/rank-business.interface";
import { RankGame } from "../../../../interfaces/custom/rank-game.interface";
import { RankLeague, RankListLeague } from "../../../../interfaces/custom/rank-league.interface";
import { RankTask } from "../../../../interfaces/custom/rank-task.interface";
import { RankBusinessDetailsComponent } from "../rank-details/rank-business-details/rank-business-details.component";
import { RankLeagueDetailsComponent } from "../rank-details/rank-league-details/rank-league-details.component";
import { RankingDetailsGame } from "../rank-details/ranking-game-details/ranking-details-game.component";
import { FILTERS } from "./consts/filters.const";
import { FILTERS_CC } from "./consts/filters-cc.const";
import { TABS } from "./consts/tabs.const";
import { TABS_CC } from "./consts/tabs-cc.const";
import { RankFilter } from "./interfaces/rank-filter.interface";
import { TabTop } from "./interfaces/tab-top.interface";

@Component({
  selector: "app-ranks",
  templateUrl: "./ranks.component.html",
})
export class RanksComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiBusinessRankService) apiBusinessRankService: ApiBusinessRankService;
  @OwInject(ApiGameRanksService) apiGameRanksService: ApiGameRanksService;
  @OwInject(ApiBusinessLeagueRankService) apiBusinessLeagueRankService: ApiBusinessLeagueRankService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(ApiPlayerService) apiPlayerService: ApiPlayerService;
  @OwInject(UserService) userService: UserService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<RanksComponent>;
  @OwInject(BusinessDateService) businessDateService: BusinessDateService;
  @OwInject(NotificationsService) notificationsService: NotificationsService;
  @OwInject(ClearNotificationService) clearNotificationService: ClearNotificationService;
  @OwInject(DashboardRoleChangeService) dashboardRoleChangeService: DashboardRoleChangeService;
  @OwInject(Store) store: Store<AppState>;

  @OwInject(MAT_DIALOG_DATA) public data: {
    tabIndex: number;
    visibleOnlyTabs: number[];
    hideNavigation?: boolean;
    owDate?: OwDate;
    rankId?: number;
    filters?: string;
    branch?: Branch;
    branchName?: string;
    fromBusinessPreviewDashboard?: boolean;
    fromLeague?: boolean;
    leagueGroup?: number;
    regionId?: number;
  };

  @Input() isInnerComponent = false;
  @Input() visibleOnlyTabs: number[] = [];
  @Input() hideNavigation?: boolean;

  paginationStart?: OwDateI;
  paginationEnd?: OwDateI;
  RANK_NAME = RANK_NAME;
  RANK_GROUP = RANK_GROUP;
  RANK_GAME_TYPE = RANK_GAME_TYPE;
  PAGINATION_TYPES = PAGINATION_TYPES;
  owPaginationDate: OwPaginationDate;
  _owDate: OwDate;
  branchName?: string;
  branch?: Branch;
  private branchSubscription: Subscription;
  private branchNameSubscription: Subscription;
  set owDate(owDate: OwDate) {
    this._owDate = owDate;
    this.owPaginationDate = new OwPaginationDate({ current: this.owDate });
  }

  get owDate(): OwDate {
    return this._owDate;
  }

  myIdObject: MyIdObject;

  tabs: TabTop[] = GAME_CONFIG.IS_CC ? TABS_CC : TABS;
  filters: RankFilter[] = GAME_CONFIG.IS_CC ? FILTERS_CC : FILTERS;

  activeTab: TabTop;
  activeFilter: RankFilter;

  rankListGame: RankGame[];
  rankListBusiness: RankListBusiness;
  rankListLeague: RankListLeague;

  reportResults: ReportResults;
  currentReportId = 0;
  selectReports: {
    label: string;
    report: Report;
    dropdownId?: number;
  }[];

  isHideRanks: boolean;
  isCleared: boolean;
  isCC = GAME_CONFIG.IS_CC;
  RANK_HALF_PDF = RANK_HALF_PDF;
  CARD_TEMPLATE = CARD_TEMPLATE;

  businessQuarter: { year: number; quarter: number };
  subs = {
    primary: null,
  };

  ngOnInit() {
    const initSub = { branch: true, name: true };
    this.branchSubscription = this.dashboardRoleChangeService.branch$.subscribe(branch => {
      if (initSub.branch === true) {
        this.branch = branch || this.data?.branch;
        setTimeout(() => {
          initSub.branch = false;
        }, 1000);
      } else {
        this.branch = branch;
      }
    });
    this.branchNameSubscription = this.dashboardRoleChangeService.branchName$.subscribe(branchName => {
      if (initSub.name === true) {
        this.branchName = branchName || this.data?.branchName;
        setTimeout(() => {
          initSub.name = false;
        }, 1000);
      } else {
        this.branchName = branchName;
      }
    });
    this.branch = this.data?.branch;

    this.myIdObject = {
      player_id: this.branch?.manager_player_id ? this.branch?.manager_player_id : this.playerService.player.id,
      branch_id: this.branch?.branch_id ? this.branch?.branch_id : this.playerService.player.branch_id,
      region_id: this.playerService.player.region_id || this.data?.regionId,
      partner_id: this.userService.me.partner_id,
      title_id: this.playerService.player.title_id.id,
      league_group_id: this.data?.leagueGroup || this.playerService.player["league_group_id"],
    };
    if (!this.isCC) {
      this.subscribePrimary();
    }
    this.checkGuiUnlocksTabs();
    this.checkInputTabs();
    this.checkOwDate();
    this.setTab(this.data?.owDate);
    this.setHideNavigation();
    this.branchName = this.data?.branchName;
  }

  subscribePrimary() {
    this.subs.primary = this.store
      .pipe(
        select(PrimarySelectors.selectPrimary),
        map(primary => {
          return primary["business_quarter"];
        }),
        take(1)
      )
      .subscribe(business_quarter => {
        this.businessQuarter = business_quarter;
      });
  }

  clearHideRanks() {
    this.isHideRanks = false;
  }

  checkOwDate() {
    if (this.data.owDate) {
      this.owDate = new OwDate(this.data.owDate);
    }
  }

  getRanksWithBranchInfo() {
    this.dashboardRoleChangeService.owDate = this.owDate;
    this.dashboardRoleChangeService.getDataWithBranchInfo(() => {
      this.myIdObject = {
        player_id: this.branch?.manager_player_id ? this.branch?.manager_player_id : this.playerService.player.id,
        branch_id: this.branch?.branch_id ? this.branch?.branch_id : this.playerService.player.branch_id,
        region_id: this.playerService.player.region_id || this.data?.regionId,
        partner_id: this.userService.me.partner_id,
        title_id: this.playerService.player.title_id.id,
        league_group_id: this.data?.leagueGroup || this.playerService.player["league_group_id"],
      };

      this.getRanks();
    }, this.branch);
  }

  checkRoleChanges() {
    if (this?.branch?.role_changed || !this?.branch) {
      this.dashboardRoleChangeService.owDate = this.owDate;
      this.dashboardRoleChangeService.checkRoleChanges();
    }
  }

  getRanks() {
    this.paginationEnd = null;
    this.isCleared = false;

    this.checkRoleChanges();

    if (!this.isCC) {
      this.paginationStart = null;
    }

    if (this.activeTab.data === "business" || this.activeTab.data === "leagues") {
      this.paginationEnd = new OwDate(this.businessDateService.currentBusinessDate);
    }

    this.clearReports();
    this.setPagination();
    this.clearHideRanks();

    switch (this.activeTab.index) {
      case RANK_NAME.BUSINESS:
        this.getBusinessRanks(this?.branch);
        break;

      case RANK_NAME.GAME:
        this.getGameRanks();
        break;

      case RANK_NAME.LEAGUE_MONTH:
      case RANK_NAME.LEAGUE_QUARTER:
      case RANK_NAME.LEAGUE_HALF:
      case RANK_NAME.LEAGUE_YEAR:
        this.getLeagueRanks();
        break;
    }
  }

  getGameRanks() {
    this.apiGameRanksService
      .getGameRanks({
        month: this.owDate.month,
        year: this.owDate.year,
        location: 1,
      })
      .subscribe(
        resp => {
          this.rankListGame = resp;
          this.showAllFilters();
        },
        errResp => {
          this.rankListGame = null;
          this.dialogService.openAlertErrorApi({ errResp });
        }
      );
  }

  getLeagueRanks() {
    let apiRequest;
    const paginationApi: string = this.activeTab.pagination;

    switch (paginationApi) {
      case PAGINATION_TYPES.MONTH:
        apiRequest = this.apiBusinessLeagueRankService.leagueRanksMonth({
          month: this.owDate.month,
          year: this.owDate.year,
          branch: this?.branch,
        });
        break;

      case PAGINATION_TYPES.QUARTER:
        if (!this.isCC) {
          if (
            this.owDate.quarter === (this.businessQuarter.quarter === 4 ? 1 : this.businessQuarter.quarter + 1) &&
            this.owDate.year === (this.businessQuarter.quarter === 4 ? this.businessQuarter.year + 1 : this.businessQuarter.year)
          ) {
            this.owDate.subtract({ quarter: 1 });
          }

          apiRequest = this.apiBusinessLeagueRankService.leagueRanksQuarter({
            quarter: this.owDate.quarter,
            year: this.owDate.year,
            branch: this?.branch,
          });
        }
        break;

      case PAGINATION_TYPES.HALF:
        if (this.owDate.year === 2023 && this.owDate.half === 2) {
          this.owDate.subtract({ month: 6 });
        }

        apiRequest = this.apiBusinessLeagueRankService.leagueRanksHalf({
          half: this.owDate.half,
          year: this.owDate.year,
          branch: this?.branch,
        });
        break;

      case PAGINATION_TYPES.YEAR:
        this.owDate = new OwDate({ year: 2023 });
        apiRequest = this.apiBusinessLeagueRankService.leagueRanksYear({
          year: this.owDate.year,
          branch: this?.branch,
        });
        break;
    }

    if (apiRequest) {
      apiRequest.subscribe(
        resp => {
          this.rankListLeague = resp;
          if (this.rankListLeague) {
            this.reloadFilters();
            this.checkPaginationLeague(paginationApi);
            if (this.rankListLeague.rankings) {
              this.isCleared = this.rankListLeague.rankings[this.activeFilter?.valueApi]?.some(rank => rank.is_cleared);
            }

            if (!this.rankListLeague.rankings && this.rankListLeague.prev) {
              this.prev();
            } else {
              this.reportDorDpp();
            }

            this.setHideRanks();
          }
        },
        errResp => {
          this.setHideRanks();
          this.rankListLeague = null;
          this.dialogService.openAlertErrorApi({ errResp });
        }
      );
    }
  }

  setHideRanks() {
    if (this.activeTab.other && this.activeTab.other.cc) {
      this.ccConditionHideRanks();
    } else {
      this.conditionHideRanks();
    }
  }

  ccConditionHideRanks() {
    this.isHideRanks = this.activeTab.pagination === PAGINATION_TYPES.MONTH && this.owDate.year === 2020 && this.owDate.month === 3;
  }

  conditionHideRanks() {
    this.isHideRanks = this.activeTab.pagination === PAGINATION_TYPES.MONTH && this.owDate.year === 2020 && this.owDate.month === 4;
  }

  checkPaginationLeague(paginationApi: string) {
    switch (paginationApi) {
      case PAGINATION_TYPES.MONTH:
        this.owPaginationDate.allowPrev.month = !!this.rankListLeague.prev;
        this.owPaginationDate.allowNext.month = !!this.rankListLeague.next;
        break;

      case PAGINATION_TYPES.QUARTER:
        this.owPaginationDate.allowPrev.quarter = !!this.rankListLeague.prev;
        this.owPaginationDate.allowNext.quarter = !!this.rankListLeague.next;

        if (!this.isCC && this.owDate.quarter === this.businessQuarter.quarter) {
          this.owPaginationDate.allowNext.quarter = false;
        }
        break;

      case PAGINATION_TYPES.HALF:
        this.owPaginationDate.allowPrev.half = !!this.rankListLeague.prev;
        this.owPaginationDate.allowNext.half = !!this.rankListLeague.next;

        if (this.owDate.year === 2022 && this.owDate.half === 2) {
          this.owPaginationDate.allowNext.half = false;
        }
        break;
    }
  }

  reportDorDpp() {
    if (this.playerService.player.title_id.id !== TITLES.DOR) {
      return;
    }

    let apiParam = { year: this.owDate.year };

    switch (this.activeTab.index) {
      case RANKS_NAME.LEAGUE_MONTH:
        apiParam = Object.assign(apiParam, { month: this.owDate.month });
        break;
      case RANKS_NAME.LEAGUE_QUARTER:
        apiParam = Object.assign(apiParam, { quarter: this.owDate.quarter });
        break;
      case RANKS_NAME.LEAGUE_HALF:
        apiParam = Object.assign(apiParam, { year_half: this.owDate.half });
        break;
      case RANKS_NAME.LEAGUE_YEAR:
        apiParam = Object.assign(apiParam, { year: 2021 });
        break;
    }

    this.apiBusinessLeagueRankService.reportDorDpp(apiParam).subscribe((resp: ReportResults) => {
      this.reportResults = resp;
      this.setSelectReport();
    });
  }

  setSelectReport() {
    if (!this.reportResults) {
      return;
    }

    setTimeout(() => {
      this.selectReports = [
        {
          label: "Wybierz raport",
          report: null,
          dropdownId: 0,
        },
      ];

      if (this.reportResults[this.activeFilter?.valueApi]) {
        const reports = this.reportResults[this.activeFilter?.valueApi];

        reports.forEach((report, index) => {
          this.selectReports.push({
            label: report.label,
            report: report,
            dropdownId: index + 1,
          });
        });

        this.currentReportId = this.selectReports[0].dropdownId;
      }
    });
  }

  downloadCsv(reportDropdownId: number) {
    const report = this.selectReports.find(x => x.dropdownId === reportDropdownId)?.report;
    this.apiBusinessLeagueRankService.reportDorDppFile(report.id).subscribe(
      resp => {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = resp.url;
        a.setAttribute("target", "_blank");
        a.click();
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  setPagination() {
    this.owPaginationDate = new OwPaginationDate({
      start: this.paginationStart ? new OwDate(this.paginationStart) : null,
      end: this.paginationEnd ? new OwDate(this.paginationEnd) : null,
      current:
        this.paginationStart && this.paginationStart.year === this.owDate.year && this.owDate.month < this.paginationStart.month
          ? new OwDate(this.paginationStart)
          : this.owDate,
    });

    if (!this.isCC && this.owDate.quarter === this.businessQuarter.quarter) {
      this.owPaginationDate.allowNext.quarter = false;
    }

    if (this.owDate.half === 2) {
      this.owPaginationDate.allowNext.half = false;
    }
  }

  clearReports() {
    this.reportResults = null;
    this.selectReports = null;
    this.clearCurrentReport();
  }

  clearCurrentReport() {
    this.changeCurrentReport(this.selectReports?.[0].dropdownId || 0);
  }

  changeCurrentReport(dropdownId: number) {
    this.currentReportId = dropdownId;
  }

  changeTab(dataTab: ChangeTab) {
    this.activeTab = dataTab.tab;
    this.isCleared = false;
    if (this.activeTab) {
      this.deleteRanks();
      if (!dataTab.isFirstTabOwDate) {
        this.resetActualDate();
      }
      this.clearActiveFilter();
      this.getRanks();
    }
  }

  deleteRanks() {
    delete this.rankListGame;
    delete this.rankListBusiness;
    delete this.rankListLeague;
  }

  resetActualDate() {
    this.owDate = new OwDate();

    switch (this.activeTab.data) {
      case "business":
      case "leagues":
        if (this.owDate.month > this.businessDateService.currentBusinessDate?.month) {
          this.owDate = new OwDate(this.businessDateService.currentBusinessDate);
        }
        break;
    }
  }

  clearActiveFilter() {
    this.activeFilter = null;
  }

  checkGuiUnlocksTabs() {
    this.tabs.forEach(tab => {
      if (tab.gui_unlocks) {
        tab.visible = this.playerService.player.gui_unlocks[tab.gui_unlocks];
      }
    });
  }

  checkInputTabs() {
    const visibleOnlyTabs = this.data.visibleOnlyTabs || this.visibleOnlyTabs;

    if (visibleOnlyTabs.length > 0) {
      this.tabs.forEach(tab => {
        tab.visible = false;

        visibleOnlyTabs.forEach(index => {
          if (tab.index === index) {
            tab.visible = true;
          }
        });
      });
    }
  }

  setTab(isOwDate?) {
    let tab;

    this.setVisibleTabs();

    if (this.data.tabIndex) {
      tab = this.tabs.find(t => t.index === this.data.tabIndex && t.visible && !t.disabledTooltip);

      if (tab) {
        this.changeTab({ tab, isFirstTabOwDate: isOwDate });
      } else {
        this.dialogService.openAlert({
          description: "Ranking jeszcze niedostępny",
        });

        setTimeout(() => {
          this.matDialogRef.close();
        });
      }
    } else {
      tab = this.tabs.find(t => t.visible && !t.disabledTooltip);
      this.changeTab({ tab });
    }
  }

  setVisibleTabs() {
    this.tabs.forEach(tab => {
      if (tab.visibilityCondition) {
        if (tab.visibilityCondition.lessThanOrEqualToTitleId) {
          tab.visible = this.playerService.firstTimeMePlayerSnapshot.title_id.id <= tab.visibilityCondition.lessThanOrEqualToTitleId;
        }

        if (tab.visibilityCondition.equalToTitleId) {
          tab.visible = this.playerService.firstTimeMePlayerSnapshot.title_id.id === tab.visibilityCondition.equalToTitleId;
        }
      }
      if (this.data?.fromBusinessPreviewDashboard && tab.data === "game-ranks") {
        tab.visible = false;
      }
      if ((this.data?.fromLeague && tab.data === "game-ranks") || (this.data?.fromLeague && tab.data === "business")) {
        tab.visible = false;
      }
    });
  }

  setHideNavigation() {
    this.hideNavigation = this.hideNavigation || this.data.hideNavigation;
  }

  openRankBusinessDetails(rank: RankTask, filters?: any) {
    if (rank.is_preparing) {
      this.dialogService.openAlert({ title: "Ups...", description: "Trwa przeliczanie rankingu. Spróbuj później." });
    } else {
      this.dialogService.open(RankBusinessDetailsComponent, {
        data: {
          rank,
          group: this.activeFilter.valueApi,
          owDate: this.owDate,
          myIdObject: this.myIdObject,
          filters,
          branchName: this?.branchName,
          branch: this?.branch,
        },
      });
    }
  }

  getBusinessRanks(branch?: Branch) {
    if (!this.isCC) {
      this.paginationStart = { year: 2024, month: 3 };
      this.setPagination();
    }

    this.apiBusinessRankService
      .businessRanks({
        month: this.owDate.month,
        year: this.owDate.year,
        branch: branch,
      })
      .subscribe(
        resp => {
          this.rankListBusiness = resp;
          if (this.rankListBusiness) {
            this.reloadFilters();
            this.sortByFavourites();
            this.setHideRanks();
            if (this.rankListBusiness.rankings) {
              this.isCleared = this.rankListBusiness.rankings[this.activeFilter?.valueApi]?.some(rank => rank.is_cleared);
            }
            if (this.data.rankId) {
              this.openRankDetails();
            }
          }
        },
        errResp => {
          this.setHideRanks();
          this.rankListBusiness = null;
          this.dialogService.openAlertErrorApi({ errResp });
        }
      );
  }

  openRankDetails() {
    let rank;

    Object.keys(this.rankListBusiness.rankings).forEach(k => {
      const myRank = this.rankListBusiness.rankings[k].find(r => r.id == this.data.rankId);
      if (myRank) {
        rank = myRank;
        const myFilter = this.filters.find(f => f.valueApi == k);
        if (myFilter) {
          this.changeFilter(myFilter);
        }
      }
    });

    if (rank) {
      this.openRankBusinessDetails(rank, this.data.filters);
    } else {
      this.dialogService.openAlert({
        description: "Nie znaleziono rankingu",
      });
    }

    setTimeout(() => {
      this.matDialogRef.close();
    });
  }

  favouriteRank(event, rank: RankTaskProcessed) {
    event.stopPropagation();

    if (rank.loading) {
      return;
    } else {
      rank.loading = true;
      this.apiBusinessRankService.patchRank(rank.id, { is_favourite: !rank.is_favourite }).subscribe(
        () => {
          rank.is_favourite = !rank.is_favourite;
          rank.loading = false;
          this.sortByFavourites();
        },
        errResp => {
          this.dialogService.openAlertErrorApi({ errResp });
          rank.loading = false;
        }
      );
    }
  }

  reloadFilters(): void {
    const ranks = this.rankListBusiness || this.rankListLeague;
    if (ranks) {
      let tmpActiveValue;
      this.filters.forEach(filter => {
        if (ranks.rankings && ranks.rankings.hasOwnProperty(filter.valueApi) && ranks.rankings[filter.valueApi].length) {
          filter.show = true;
          if (!tmpActiveValue) {
            tmpActiveValue = R.clone(filter);
          }
        } else {
          filter.show = false;
          if (this.activeFilter && this.activeFilter.valueApi === filter.valueApi) {
            this.activeFilter.show = false;
          }
        }
      });

      if (!this.activeFilter) {
        this.changeFilter(tmpActiveValue);
      }
    }
  }

  changeFilter(filter: RankFilter): void {
    this.activeFilter = R.clone(filter);
    this.selectReports = null;
    this.clearCurrentReport();
    this.setSelectReport();
    this.setLabelFilters();

    if (this.activeTab.data === "game-ranks") {
      this.setVisibleRanks(this.rankListGame, []);
    }
  }

  showAllFilters(): void {
    this.filters.forEach((filter: RankFilter) => {
      if (filter.conditions) {
        filter.conditions.forEach(conditionId => {
          if (this.activeTab.index === conditionId) {
            filter.show = true;
          }
        });
      } else if (filter.excludes) {
        filter.excludes.forEach(excludeId => {
          if (this.activeTab.index === excludeId) {
            filter.show = false;
          }
        });
      } else {
        filter.show = true;
      }
    });

    this.changeFilter(this.filters[0]);
  }

  setLabelFilters() {
    this.filters.forEach(filter => {
      filter.displayLabel = filter.label;

      if (filter.customLabels) {
        Object.keys(filter.customLabels).forEach((key: string) => {
          if (this.activeTab.index === parseInt(key, 10)) {
            filter.displayLabel = filter.customLabels[key];
          }
        });
      }
    });
  }

  setVisibleRanks(arrayRank: any[], arrayToHiddenRank: number[]) {
    arrayRank.forEach(rank => {
      rank.show = !arrayToHiddenRank.some(rankType => rank.type === rankType);
    });
  }

  sortByFavourites(): void {
    Object.keys(this.rankListBusiness.rankings).forEach(key => {
      // TODO check why false-positive tslint error
      // @ts-ignore
      this.rankListBusiness.rankings[key] = R.sort(R.descend(R.prop("is_favourite")), this.rankListBusiness.rankings[key]);
    });
  }

  prev() {
    switch (this.activeTab.pagination) {
      case PAGINATION_TYPES.MONTH:
        if (this.activeTab.index === RANKS_NAME.LEAGUE_MONTH) {
          this.owDate.set({
            month: this.rankListLeague.prev.value,
            year: this.rankListLeague.prev.year,
          });
        } else {
          this.owDate.subtract({ month: 1 });
        }
        break;

      case PAGINATION_TYPES.QUARTER:
        if (this.activeTab.index === RANKS_NAME.LEAGUE_QUARTER) {
          this.owDate.setQuarter({
            quarter: this.rankListLeague.prev.value,
            year: this.rankListLeague.prev.year,
          });
        } else {
          this.owDate.subtract({ quarter: 1 });
        }
        break;

      case PAGINATION_TYPES.HALF:
        if (this.activeTab.index === RANKS_NAME.LEAGUE_HALF) {
          this.owDate.setHalf({
            half: this.rankListLeague.prev.value,
            year: this.rankListLeague.prev.year,
          });
        } else {
          this.owDate.subtract({ month: 6 });
        }
        break;
    }
    if (this?.branch && !this?.branch?.role_changed) {
      this.getRanksWithBranchInfo();
    } else {
      this.getRanks();
    }
  }

  next() {
    switch (this.activeTab.pagination) {
      case PAGINATION_TYPES.MONTH:
        if (this.activeTab.index === RANKS_NAME.LEAGUE_MONTH) {
          this.owDate.set({
            month: this.rankListLeague.next.value,
            year: this.rankListLeague.next.year,
          });
        } else {
          this.owDate.add({ month: 1 });
        }
        break;

      case PAGINATION_TYPES.QUARTER:
        if (this.activeTab.index === RANKS_NAME.LEAGUE_QUARTER) {
          this.owDate.setQuarter({
            quarter: this.rankListLeague.next.value,
            year: this.rankListLeague.next.year,
          });
        } else {
          this.owDate.add({ quarter: 1 });
        }
        break;

      case PAGINATION_TYPES.HALF:
        if (this.activeTab.index === RANKS_NAME.LEAGUE_HALF) {
          this.owDate.setHalf({
            half: this.rankListLeague.next.value,
            year: this.rankListLeague.next.year,
          });
        } else {
          this.owDate.add({ month: 6 });
        }
        break;
    }

    if (this?.branch && !this?.branch?.role_changed) {
      this.getRanksWithBranchInfo();
    } else {
      this.getRanks();
    }
  }

  openRankLeagueDetails(rank: RankLeague) {
    if (rank.is_preparing) {
      this.dialogService.openAlert({ title: "Ups...", description: "Trwa przeliczanie rankingu. Spróbuj później." });
    } else {
      this.dialogService.open(RankLeagueDetailsComponent, {
        data: {
          rank,
          group: this.activeFilter.valueApi,
          owDate: this.owDate,
          myIdObject: this.myIdObject,
          pagination: this.activeTab.pagination,
          branchName: this.branchName,
          branch: this?.branch,
        },
      });
    }
  }

  openRankGameDetails(rank: RankGame) {
    if (R.path(["parameters", "tooltipInList"], rank)) {
      return;
    }
    this.dialogService.open(RankingDetailsGame, {
      data: {
        rankId: rank.rank_edition_id,
        group: this.activeFilter.valueApi,
        inactiveBranchIndicator: rank?.parameters?.inactive_branch_indicator,
      },
    });
  }

  clearNotification() {
    this.clearNotificationService.clearNotification(["import_calculate_ranks"], this.branch?.manager_player_id);
  }

  ngOnDestroy() {
    this.clearNotification();
    this.branchSubscription.unsubscribe();
    this.branchNameSubscription.unsubscribe();
    if (!this.isCC) {
      unsubscribeObject(this.subs);
    }
  }
}

interface ReportResults {
  branch: Report[];
  employee: Report[];
  managers: Report[];
  region: Report[];
}

interface Report {
  filename: string;
  id: number;
  label: string;
  type: string;
}

interface ChangeTab {
  tab: TabTop;
  isFirstTabOwDate?: OwDate;
}

const RANK_HALF_PDF = {
  H1: {
    BRANCH:
      "https://sanwonderland.santanderconsumer.pl/api/files/download/2f3230a3-d329-4e60-9743-f0b2e354308a/ODDZIA%C5%81Y_grupy_ranking.pdf",
    REGION: "https://sanwonderland.santanderconsumer.pl/api/files/download/976dd6ef-601b-444d-8964-c20d241f896f/REGIONY_ranking.pdf",
  },
};
