import { ChangeDetectionStrategy, Component, Input, OnChanges } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../../../../../core/abstracts/abstract-inject-base.component";
import { GAME_CONFIG } from "../../../../../../../../../../core/config/custom/_parsed-game.config";
import { OwInject } from "../../../../../../../../../../core/decorators/ow-inject.decorator";
import { Player } from "../../../../../../../../../player/interfaces/player";
import { PlayerService } from "../../../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../../../shared/providers/dialog.service";
import { ChangeSceneService } from "../../../../../../../../../shared/services/custom/change-scene.service";
import { STOCK_VIEW } from "../../../../../../../shared-ui/mobile/consts/stock-view.const";

@Component({
  selector: "ranking-game-table",
  templateUrl: "./ranking-game-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RankingGameTableComponent extends AbstractInjectBaseComponent implements OnChanges {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(ChangeSceneService) changeSceneService: ChangeSceneService;

  @Input() rankResults;
  @Input() rank;
  @Input() columns;
  @Input() myPositionIndex;
  @Input() inputSearchValue;
  @Input() group;
  @Input() isWfRank;
  @Input() inactiveBranchIndicator;

  ALLOW_CHANGE_PLAYER = GAME_CONFIG.ALLOW_CHANGE_PLAYER;
  STOCK_VIEW = STOCK_VIEW;

  ngOnChanges() {
    this.mapPrizes();
  }

  changePlayer(playerId) {
    if (
      !this.ALLOW_CHANGE_PLAYER ||
      !(this.group === "employee" || this.group === "managers") ||
      (this.rank.parameters && this.rank.parameters.disabled_preview_player)
    ) {
      return;
    }

    if (playerId === this.playerService.getActivePlayerId()) {
      this.dialogService.openAlert({
        description: "Właśnie oglądasz tego gracza.",
      });
      return;
    }

    this.changeSceneService.changePlayer(<Player>{ id: playerId });
  }

  mapPrizes() {
    if (!this.rank.prizes || !this.rankResults) {
      return;
    }
    this.rankResults.results.forEach((item, index) => {
      item.prizes = this.rank.prizes[index];
    });
  }
}
