<table class="custom" *ngIf="rankResults">
  <tbody>
  <tr
    *ngFor="let item of rankResults.results | filterObject:inputSearchValue; let i = index"
    [class.my-position]="myPositionIndex === i && !(inputSearchValue) && playerService.isActiveMe"
    [class.player-inactive]="item?.active === false && inactiveBranchIndicator"
    [attr.data-id]="i"
  >
    <ng-container *ngFor="let column of columns">
      <!-- FE COLUMN -->
      <ng-container *ngIf="!column.columnFromApi">
        <!-- PRIZES COLUMN -->
        <td
          *ngIf="column.prizes"
          [ngClass]="column.classes"
        >
          <div class="prizes" *ngIf="item.prizes">
            <!-- CURRENCY PRIZES -->
            <m-ui-currency
              *ngFor="let currency of item.prizes.currency_prizes"
              [item]="currency"
              [stockView]="STOCK_VIEW.G"
              [lack]="false"
            ></m-ui-currency>

            <!-- PRODUCT PRIZES -->
            <m-ui-product
              *ngFor="let product of item.prizes.product_prizes"
              [item]="product"
              [stockView]="STOCK_VIEW.G"
              [hideValue]="true"
              [lack]="false"
            ></m-ui-product>
          </div>

          <div class="prizes" *ngIf="item.currency_prizes || item.product_prizes">
            <!-- CURRENCY PRIZES -->
            <m-ui-currency
              *ngFor="let currency of item.currency_prizes"
              [item]="currency"
              [stockView]="STOCK_VIEW.G"
              [lack]="false"
            ></m-ui-currency>

            <!-- PRODUCT PRIZES -->
            <m-ui-product
              *ngFor="let product of item.product_prizes"
              [item]="product"
              [stockView]="STOCK_VIEW.G"
              [lack]="false"
            ></m-ui-product>
          </div>
        </td>

        <td
          *ngIf="column.custom"
          [ngClass]="column.classes"
        >
          <!-- BASIC -->
          <ng-container
            *ngIf="column.template === 'basic'"
            [ngTemplateOutlet]="basicTpl"
            [ngTemplateOutletContext]="{item: item}"
          ></ng-container>

          <!-- BRANCH NAME -->
          <ng-container
            *ngIf="column.template === 'branch-name'"
            [ngTemplateOutlet]="branchNameTpl"
            [ngTemplateOutletContext]="{item: item}"
          ></ng-container>

          <!-- REGION NAME -->
          <ng-container
            *ngIf="column.template === 'region-name'"
            [ngTemplateOutlet]="regionNameTpl"
            [ngTemplateOutletContext]="{item: item}"
          ></ng-container>

          <!-- PRIZE -->
          <ng-container
            *ngIf="column.template === 'prize'"
            [ngTemplateOutlet]="prizeTpl"
            [ngTemplateOutletContext]="{item: item}"
          ></ng-container>
        </td>

        <!-- DEFAULT COLUMN -->
        <td
          *ngIf="column.default"
          [ngClass]="column.classes"
        >
          {{ item[column.value] }}
        </td>
      </ng-container>

      <!-- DELTA -->
      <ng-container *ngIf="column.delta && rank.type === 1">
        <td [ngClass]="column.classes">
          {{ item.delta === 'new' ? 'nowy' : item.delta }}
        </td>
      </ng-container>

      <!-- BE COLUMN -->
      <ng-container *ngIf="column.columnFromApi">
        <td
          *ngIf="rank['value' + column.columnFromApi + '_label']"
          [matTooltip]="isWfRank ? item.wfRankTooltip : item['value' + column.columnFromApi + '_tooltip_' + group] || item['value' + column.columnFromApi + '_tooltip'] || rank['value' + column.columnFromApi + '_tooltip']"
          [matTooltipShowDelay]="400"
          [matTooltipPosition]="'left'"
          [matTooltipClass]="'wf-tooltip'"
          [ngClass]="column.classes"
          [class]="'rid-' + rank.rank_id + '_group-' + group + '_pid-' + item.player_id"
          [class.fw-normal]="isWfRank && (column.columnFromApi == 2 || column.columnFromApi == 3)"
        >
          {{ item['value' + column.columnFromApi] }}
        </td>
      </ng-container>
    </ng-container>
  </tr>
  </tbody>
</table>

<!-- BASIC TPL -->
<ng-template #basicTpl let-item="item">
  <span
    [class.allow-change-player]="(group === 'employee' || group === 'managers') && ALLOW_CHANGE_PLAYER && !rank.parameters?.disabled_preview_player"
    (click)="changePlayer(item.player_id)"
  >
    {{ item.first_name }} {{ item.last_name }} - {{ item.branch_name }}
  </span>
</ng-template>

<!-- BRANCH NAME TPL -->
<ng-template #branchNameTpl let-item="item">
  {{ item.branch_name }}
</ng-template>

<!-- REGION NAME TPL -->
<ng-template #regionNameTpl let-item="item">
  {{ item.region_name }}
</ng-template>

<!-- PRIZE TPL -->
<ng-template #prizeTpl let-item="item">
  <div
    class="icon-prize"
    [ngbTooltip]="'Zaproszenie do udziału w wielkofinałowym evencie'"
    container="body"
    placement="left"
    [innerHTML]="item.prize | resourceFromString"
  ></div>
</ng-template>




