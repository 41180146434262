import { RankFilter } from "../../../../../../san-ranks/basic/custom/dialogs/ranks/interfaces/rank-filter.interface";
import { RANK_GROUP } from "../../../../../../san-ranks/const/custom/rank-group.const";
import { RANK_NAME } from "../../../../../../san-ranks/const/custom/rank-name.const";

export const FILTERS_CC: RankFilter[] = [
  {
    valueApi: RANK_GROUP.EMPLOYEE,
    label: "Konsultanci",
    customLabels: { [RANK_NAME.GAME]: "Indywidualny" },
    show: false,
  },
  {
    valueApi: RANK_GROUP.BRANCH_MANAGERS,
    label: "Teamy",
    show: false,
  },
];
